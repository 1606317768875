import { useEffect, useState } from "react";

function getDimensions() {
  if (
    typeof window === "undefined" ||
    !window.document ||
    typeof document === "undefined"
  )
    return { undefined, undefined, undefined, undefined };

  const { innerWidth: windowInnerWidth, innerHeight: windowInnerHeight } =
    window;
  const {
    scrollWidth: documentScrollWidth,
    scrollHeight: documentScrollHeight,
  } = document.documentElement;

  return {
    windowInnerWidth,
    windowInnerHeight,
    documentScrollWidth,
    documentScrollHeight,
  };
}

export default function useDimensions() {
  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    function handleResize() {
      setDimensions(getDimensions());
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
}
