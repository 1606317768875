import React, { forwardRef } from "react";
import { StaticQuery, graphql } from "gatsby";

import SpringLink from "./SpringLink";
import { StaticImage } from "gatsby-plugin-image";
import _ from "lodash";
import Anmeldeschein from "../../content/media/Anmeldeschein-für-Mietinteressenten.pdf";
import Datenschutzerklaerung from "../../content/media/datenschutzerklaerung.pdf";

const Footer = forwardRef(({ style }, ref) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___title] }
            filter: {
              fileAbsolutePath: { regex: "/(properties)/" }
              frontmatter: { draft: { ne: true } }
            }
          ) {
            edges {
              node {
                frontmatter {
                  title
                }
                fields {
                  slug
                }
                fileAbsolutePath
              }
            }
          }
        }
      `}
      render={(data) => (
        <footer
          className="di-footer footer feature feature-primary"
          style={style}
          ref={ref}
        >
          <section className="container grid-960">
            <div className="columns">
              <div className="column col-5 col-md-8 col-sm-12">
                <StaticImage
                  src="../images/branding/logo-vertical-light.svg"
                  className="mb-10"
                  height={80}
                  alt="Densa Immobilien AG Logo"
                  placeholder="tracedSVG"
                  layout="constrained"
                />
                <p>&copy; 2021 DENSA Immobilien AG</p>
                <p>
                  Tel. +41 61 512 45 45
                  <br />
                  <a href="mailto:verwaltung@densaimmobilien.ch">
                  verwaltung@densaimmobilien.ch
                  </a>
                  <br />
                  <br />
                  <u>Vermietung:</u>
                  <br />
                  <a href="mailto:vermietung@densaimmobilien.ch">
                  vermietung@densaimmobilien.ch
                  </a>
                  <br />
                  <br />
                  <u>Download:</u>
                  <br />
                  <a href={Anmeldeschein} target="_blank" rel="noreferrer">
                  Formular Bewerbung Wohnung
                  </a>
                  <br />
                  <br />
                  <u>Datenschutz:</u>
                  <br />
                  <a href={Datenschutzerklaerung} target="_blank" rel="noreferrer">
                  Datenschutzerklärung
                  </a>
                </p>
              </div>

              <div className="column col-3 col-md-4 col-sm-12">
                <SpringLink to="/ueber-uns" className="block">
                  Über uns
                </SpringLink>

                {_.map(data.allMarkdownRemark.edges, (edge) => {
                  const { frontmatter, fields } = edge.node;

                  return (
                    <SpringLink
                      to={fields.slug}
                      className="block"
                      key={fields.slug}
                    >
                      {frontmatter.title}
                    </SpringLink>
                  );
                })}
              </div>
              <div className="column col-4 col-md-12 col-sm-12">
                <h4>Interessiert an offenen Wohnungen?</h4>
                <p>Melden Sie sich an und erhalten Sie eine Nachricht, sobald eine Wohnung frei wird</p>
                <form name="signup-free-appartments" method="POST" data-netlify="true">
                  <input type="hidden" name="form-name" value="signup-free-appartments" />
                  <p>
                    <label>E-Mail-Adresse: <input type="email" name="email" /> </label>
                  </p>
                  <p>
                    <button type="submit" className="btn">Absenden</button>
                  </p>
                </form>
              </div>
            </div>
          </section>
        </footer>
      )}
    />
  );
});

export default Footer;
