import React, { createRef, useEffect, useState } from "react";

import Footer from "./Footer";
import { TransitionState } from "gatsby-plugin-transition-link";
import { motion } from "framer-motion";
import useDimensions from "../utils/hooks/useDimensions";

const AnimatedPage = ({ children }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(false);

  const footer = createRef();
  const { documentScrollHeight, windowInnerHeight } = useDimensions();

  useEffect(() => {
    setFooterHeight(footer.current?.offsetHeight);
  }, [footer]);

  useEffect(() => {
    setShouldScroll(documentScrollHeight > windowInnerHeight + footerHeight);
  }, [footerHeight, documentScrollHeight, windowInnerHeight]);

  return (
    <TransitionState>
      {({ transitionStatus, exit, entry }) => {
        const mount = ["entering", "entered"].includes(transitionStatus);
        const seconds = mount ? entry.length : exit.length;

        return (
          <motion.div
            initial={entry.state}
            animate={mount ? { opacity: 1, x: 0, y: 0 } : exit.state}
            transition={{ duration: seconds, type: "tween" }}
          >
            <div
              id="di-main-content"
              style={
                shouldScroll ? { marginBottom: footerHeight + "px" } : null
              }
            >
              {children}
            </div>

            <Footer
              ref={footer}
              style={shouldScroll ? { position: "fixed" } : null}
            />
          </motion.div>
        );
      }}
    </TransitionState>
  );
};

export default AnimatedPage;
